// extracted by mini-css-extract-plugin
export var column = "UserNetworkSdWanProject__column__eAwSo";
export var container = "UserNetworkSdWanProject__container__BEY94";
export var contentWrapper = "UserNetworkSdWanProject__contentWrapper__VtzXr";
export var flex = "UserNetworkSdWanProject__flex__WAcav";
export var flexColumn = "UserNetworkSdWanProject__flexColumn__z9Ite";
export var gap1 = "UserNetworkSdWanProject__gap1__wGmVW";
export var gap2 = "UserNetworkSdWanProject__gap2__MLON4";
export var gap3 = "UserNetworkSdWanProject__gap3__t7Yvc";
export var gap4 = "UserNetworkSdWanProject__gap4__rBRPG";
export var gap5 = "UserNetworkSdWanProject__gap5__s6EaR";
export var row = "UserNetworkSdWanProject__row__UjMmc";