// extracted by mini-css-extract-plugin
export var colLeft = "UserNetworkSdWanProject_Details__colLeft__XoTFA";
export var colRight = "UserNetworkSdWanProject_Details__colRight__s3UsT";
export var column = "UserNetworkSdWanProject_Details__column__fupNT";
export var columnWrapper = "UserNetworkSdWanProject_Details__columnWrapper__XZFF7";
export var descriptionContent = "UserNetworkSdWanProject_Details__descriptionContent__e15Mi";
export var descriptionHeading = "UserNetworkSdWanProject_Details__descriptionHeading__qHVuZ";
export var flex = "UserNetworkSdWanProject_Details__flex__gj7Wj";
export var flexColumn = "UserNetworkSdWanProject_Details__flexColumn__jseHH";
export var gap1 = "UserNetworkSdWanProject_Details__gap1__TapxZ";
export var gap2 = "UserNetworkSdWanProject_Details__gap2__wvhYd";
export var gap3 = "UserNetworkSdWanProject_Details__gap3__xHiJS";
export var gap4 = "UserNetworkSdWanProject_Details__gap4__pYt8H";
export var gap5 = "UserNetworkSdWanProject_Details__gap5__Qfka2";
export var lastUpdated = "UserNetworkSdWanProject_Details__lastUpdated__L_6_Q";
export var reviewStatusWarning = "UserNetworkSdWanProject_Details__reviewStatusWarning__MuBJZ";
export var row = "UserNetworkSdWanProject_Details__row__SbAEf";