// extracted by mini-css-extract-plugin
export var additionalNotes = "UserNetworkSdWanProject_Details_RequiredProducts__additionalNotes__g1N4Z";
export var cardTitle = "UserNetworkSdWanProject_Details_RequiredProducts__cardTitle__muhBs";
export var checkboxes = "UserNetworkSdWanProject_Details_RequiredProducts__checkboxes__ZjJyB";
export var checkboxesContainer = "UserNetworkSdWanProject_Details_RequiredProducts__checkboxesContainer__vL3G1";
export var column = "UserNetworkSdWanProject_Details_RequiredProducts__column__jM4Bl";
export var descriptionHeading = "UserNetworkSdWanProject_Details_RequiredProducts__descriptionHeading__UEmJ_";
export var flex = "UserNetworkSdWanProject_Details_RequiredProducts__flex__Jyo2R";
export var flexColumn = "UserNetworkSdWanProject_Details_RequiredProducts__flexColumn__MhdIJ";
export var gap1 = "UserNetworkSdWanProject_Details_RequiredProducts__gap1__gPayl";
export var gap2 = "UserNetworkSdWanProject_Details_RequiredProducts__gap2__Y7VMB";
export var gap3 = "UserNetworkSdWanProject_Details_RequiredProducts__gap3__DKZL5";
export var gap4 = "UserNetworkSdWanProject_Details_RequiredProducts__gap4__EFpr3";
export var gap5 = "UserNetworkSdWanProject_Details_RequiredProducts__gap5__wzZzg";
export var headingGroup = "UserNetworkSdWanProject_Details_RequiredProducts__headingGroup__tMvDx";
export var headingLicenseGroup = "UserNetworkSdWanProject_Details_RequiredProducts__headingLicenseGroup__NkXia";
export var headingName = "UserNetworkSdWanProject_Details_RequiredProducts__headingName__kFuYr";
export var lightBackground = "UserNetworkSdWanProject_Details_RequiredProducts__lightBackground__MEMbC";
export var locationCard = "UserNetworkSdWanProject_Details_RequiredProducts__locationCard__aqEbx";
export var locationText = "UserNetworkSdWanProject_Details_RequiredProducts__locationText__GnFuw";
export var mapImage = "UserNetworkSdWanProject_Details_RequiredProducts__mapImage__kDIW4";
export var notesContainer = "UserNetworkSdWanProject_Details_RequiredProducts__notesContainer__AgNTG";
export var panel = "UserNetworkSdWanProject_Details_RequiredProducts__panel__c7uiu";
export var panelBody = "UserNetworkSdWanProject_Details_RequiredProducts__panelBody__JKeX_";
export var panelHeading = "UserNetworkSdWanProject_Details_RequiredProducts__panelHeading__OnSDo";
export var productName = "UserNetworkSdWanProject_Details_RequiredProducts__productName__YtNZ4";
export var productRow = "UserNetworkSdWanProject_Details_RequiredProducts__productRow__nHNen";
export var productServiceLabel = "UserNetworkSdWanProject_Details_RequiredProducts__productServiceLabel__yjeDJ";
export var productServiceValue = "UserNetworkSdWanProject_Details_RequiredProducts__productServiceValue__B4rX5";
export var productsServiceWrapper = "UserNetworkSdWanProject_Details_RequiredProducts__productsServiceWrapper___4UlM";
export var propertiesContainer = "UserNetworkSdWanProject_Details_RequiredProducts__propertiesContainer__CRbzp";
export var propertyLeft = "UserNetworkSdWanProject_Details_RequiredProducts__propertyLeft__yGygk";
export var propertyRight = "UserNetworkSdWanProject_Details_RequiredProducts__propertyRight__cU_CU";
export var requirements = "UserNetworkSdWanProject_Details_RequiredProducts__requirements__ZljTB";
export var requirementsBlock = "UserNetworkSdWanProject_Details_RequiredProducts__requirementsBlock__voaXV";
export var row = "UserNetworkSdWanProject_Details_RequiredProducts__row__EpSWS";
export var stayShaded = "UserNetworkSdWanProject_Details_RequiredProducts__stayShaded__fVm_t";
export var switchLight = "UserNetworkSdWanProject_Details_RequiredProducts__switchLight__aRnWR";
export var switchShaded = "UserNetworkSdWanProject_Details_RequiredProducts__switchShaded__YfZ_j";
export var tickmarkIcon = "UserNetworkSdWanProject_Details_RequiredProducts__tickmarkIcon__YGcaM";
export var tileContainer = "UserNetworkSdWanProject_Details_RequiredProducts__tileContainer__u0sof";
export var tileMap = "UserNetworkSdWanProject_Details_RequiredProducts__tileMap__HHbrx";
export var withColon = "UserNetworkSdWanProject_Details_RequiredProducts__withColon__JMOKh";